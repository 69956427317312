import { run, hasSufficientPerformance, setup } from '#speedkit/utils/performance';
import { triggerRunCallback, observeSpeedkitButton, setupSpeedkitLayer, updateSpeedkitLayerMessage, initReducedView } from '#speedkit/utils/entry';
import Deferred from '#speedkit/classes/Deferred';
import { isSupportedBrowser } from '#speedkit/utils/browser';


  // vite
export default entryWrapper();

function entryWrapper(){

  if (!process.server) {
    return client().then(() => getEntry());
  } else {
    return async (ctx) => (await getEntry())(ctx)
  }

};

function getEntry(){
  return import('/var/www/html/node_modules/nuxt/dist/app/entry').then(module => module.default);
}

function client () {
  const deferred = new Deferred();

  let initialized = false
  const layerEl = window.document.getElementById('nuxt-speedkit-layer');

  const forceInit = ('__NUXT_SPEEDKIT_FORCE_INIT__' in window && window.__NUXT_SPEEDKIT_FORCE_INIT__);

  async function initApp(force) {
    if (initialized) {
      deferred.resolve();
    }

    document.documentElement.classList.remove('nuxt-speedkit-reduced-view');

    try {

      
if (!force) {
        await run();
      }


      initialized = true;

      triggerRunCallback(true);

      deferred.resolve();

    } catch (error) {
      triggerRunCallback(false);

      if (!!layerEl) {
        // User must interact via the layer.
        updateSpeedkitLayerMessage(layerEl, 'nuxt-speedkit-message-weak-hardware');
        return null;
      }
    }

    return null;
  };

  const supportedBrowser = isSupportedBrowser({regex: new RegExp("Edge?\u005C\u002F(12\u005Cd|1[3-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Firefox\u005C\u002F(1{2}[5-9]|1[2-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Chrom(ium|e)\u005C\u002F(109|1[1-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|(Maci|X1{2}).+ Version\u005C\u002F(16\u005C.([6-9]|\u005Cd{2,})|(1[7-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)([,.]\u005Cd+|)( \u005C(\u005Cw+\u005C)|)( Mobile\u005C\u002F\u005Cw+|) Safari\u005C\u002F|Chrome.+OPR\u005C\u002F(10[5-9]|1[1-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+\u005C.\u005Cd+|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(15[._]([6-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})[._]\u005Cd+)([._]\u005Cd+|)|Opera Mini|Android:?[ \u002F-](12[1-9]|1[3-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})(\u005C.\u005Cd+|)(\u005C.\u005Cd+|)|Mobile Safari.+OPR\u005C\u002F(7[3-9]|[89]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+\u005C.\u005Cd+|Android.+Firefox\u005C\u002F(12[2-9]|1[3-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Android.+Chrom(ium|e)\u005C\u002F(12[1-9]|1[3-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Android.+(UC? ?Browser|UCWEB|U3)[ \u002F]?(15\u005C.([5-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)\u005C.\u005Cd+|SamsungBrowser\u005C\u002F(2[2-9]|[3-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+|Android.+MQ{2}Browser\u005C\u002F(13(\u005C.([1-9]|\u005Cd{2,})|)|(1[4-9]|[2-9]\u005Cd|\u005Cd{3,})(\u005C.\u005Cd+|))(\u005C.\u005Cd+|)|K[Aa][Ii]OS\u005C\u002F(2\u005C.([5-9]|\u005Cd{2,})|([3-9]|\u005Cd{2,})\u005C.\u005Cd+)(\u005C.\u005Cd+|)", "")});

  window.addEventListener('load', function () {
    if (!document.getElementById('nuxt-speedkit-layer')) {
      initApp(forceInit);
    } else {

      observeSpeedkitButton('nuxt-speedkit-button-init-reduced-view', initReducedView);
      observeSpeedkitButton('nuxt-speedkit-button-init-app', () => initApp(true));

      setup({"timing":{"fcp":800,"dcl":1200},"device":{"hardwareConcurrency":{"min":2,"max":48},"deviceMemory":{"min":2}}});

      if(('__NUXT_SPEEDKIT_AUTO_INIT__' in window && window.__NUXT_SPEEDKIT_AUTO_INIT__) || ((true && hasSufficientPerformance()) && supportedBrowser)) {
        initApp();
      } else {
        setupSpeedkitLayer(layerEl, supportedBrowser)
      }

    }
  });

return deferred.promise;

}
